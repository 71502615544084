::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  background-color: #e5e5e5;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6580af;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.report-icons-head--icons {
  white-space: nowrap;
}
.error-class {
  color: red; /* Change text color to red */
  font-size: 12px; /* Adjust font size */
  /* Add more styles as needed */
}
.card-element-container {
  padding: 0.6rem 1rem;
  border-radius: 4px;
  min-height: 16px;
  position: relative;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.client-report-icons-head--icons {
  white-space: nowrap;
}
.list-group-item + .list-group-item {
  border-top: 1px solid rgba(34, 41, 47, 0.125) !important;
}
.mlr-1rem {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.zIndex-100 {
  z-index: 100 !important;
}

.w-available {
  width: -webkit-fill-available !important;
}

.fc .fc-popover {
  z-index: 12 !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.input-group {
  z-index: 10 !important;
}

.invalid {
  border: 1px solid red !important;
}
.w-1001Percent {
  width: '250px' !important;
}
.w-100Percent {
  width: 8rem !important;
  span {
    width: 100%;
  }
}
.error {
  color: #ea5455 !important;
}

.modal-open {
  padding-right: 0 !important;
}
.form-select {
  background-position: right 1rem center !important;
}
* {
  font-family: 'Poppins', sans-serif !important;
  p {
    margin: 0;
    padding: 0;
  }
}
.fw-bold {
  font-weight: bold !important;
}
.datePicker-non-visible {
  width: 1px !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

.searchMarginBottom {
  @include breakpoint-down(large) {
    margin-bottom: 1rem;
  }
}

.Trash {
  color: gray;
}

.mx-5px {
  margin-left: 5px;
  margin-right: 5px;
}

.chevron-left {
  transition: transform 250ms;

  &:hover {
    transform: translateX(-5px);
  }
}

.t-black {
  color: $black;
}

.t-gray {
  color: $gray-700;
}

.t-ethera {
  color: $ethera-text-color;
}

.t-g-b {
  color: $gray-700;
}

.t-white {
  color: $white;
}

.pd {
  padding: $btn-sm-padding-without-icon;
}

.pd-s {
  padding: 0.4rem 1rem;
}

.fwl {
  font-weight: 400;
}

.form-field-padding {
  padding: 3px 15px !important;
}

.inverted-color {
  color: $black;
}

.fit-content {
  width: fit-content !important;
}

.bg-ethera {
  background-color: $bg-ethera;
}

.bg-purple {
  background-color: #9faac9 !important;
  color: black;
}
.width-title {
  width: 60% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include breakpoint-down(xxxxLarge) {
    max-width: 31rem !important;
  }
}
.width-check {
  @include breakpoint-up(med) {
    max-width: 60%;
  }

  @include breakpoint-up(large) {
    max-width: 50%;
  }
}

.mt-5px {
  margin-top: 5px;
}

.required-dot::after {
  content: '*';
  height: 12px;
  width: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: red;
  display: block;
}

.required-dot-message {
  position: absolute;
  // top: -6px;
  right: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background-color: red;
}

.text-align-center {
  text-align: center !important;
}

.cursorPointer,
.pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right !important;
}

.whiteSpace {
  white-space: nowrap;
}

.fullWidth {
  width: -webkit-fill-available;
}

.pd-table {
  padding: 1.5rem 2rem !important;
}

.font-bold {
  font-weight: bold;
}
.marginLeftAuto {
  margin-left: auto;
}

.text-align-end {
  text-align: end;
}

.bg-transparent {
  background-color: transparent !important;
}

.ml-35 {
  margin-left: 35px;
}

.ml-70 {
  margin-left: 70px;
}
.marginAuto {
  margin-left: auto;
  margin-right: auto;
}

.bg-white {
  background: white;
}

.danger-ethera {
  color: $ethera-text-color;
}

.m-10px {
  margin: 10px;
}
.h-6 {
  height: 7rem !important;
}
.pl-10px {
  padding-left: 10px;
}
.mt-21 {
  margin-top: -21px !important;
}

.pl-20px {
  padding-left: 20px;
}
.pl-28px {
  padding-left: 28px;
}

.ml-8px {
  margin-left: 8px;
}
.ml-10px {
  margin-left: 10px;
}
.ml-5px {
  margin-left: 5px;
}

.mr-5px {
  margin-right: 5px;
}
.set-circle-size {
  width: 20px;
  height: 20px;
}
.background-f5f5f5 {
  background-color: #f5f5f5;
}

.yellow-selector {
  background-color: $ethera-yellow-light;
  border: 1px solid $ethera-yellow-border;
}

.text-6e6b7b {
  color: #6e6b7b !important;
}

.width-150 {
  max-width: 150px !important;
}

.width-250 {
  width: 250px;
}

.input-control {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2) !important;

  padding: 3px 15px;

  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.icon-control {
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-left: 0 !important;

  padding: 3px 15px;

  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.DOT {
  min-height: 10px;
  min-width: 10px;
  max-height: 11px;
  max-width: 11px;
  margin-left: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  &--green {
    background-color: yellowgreen;
  }

  &--red {
    background-color: #c51615;
  }

  &--yellow {
    background-color: yellow;
  }
}

.logo-text {
  color: $bg-dark;
  font-size: 20px;
  margin-left: 5px !important;
}

.ethera-modal-top-background {
  background-color: $ethera-gray-dark !important;
  border-radius: 0 !important;
}

.text-ethera-dark {
  color: $ethera-text-color !important;
}

.dark-logo-text {
  color: #fefefe !important;
}

.layout-light {
  background-color: $ethera-selected;
  color: $black;
}

.layout-dark {
  background-color: $bg-dark;
  color: $white;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.shadow-none {
  box-shadow: none !important;
}

.calendarIcon {
  background: $ether-calendar-active;
  display: inline-block;
  padding: 7px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
}
.search {
  right: 100px;
  width: 300px;
}

.AddButton {
  right: 20px;
  & button {
    background: $ethera-green;
    display: inline-block;
    padding: 10px;
    color: #fff;
  }
}

.text-ellipsis {
  white-space: nowrap !important;
  width: auto !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.selectFormGroup {
  margin: 0 !important;
}

.datePicker {
  width: 5px !important;
  padding: 0.271rem 1rem !important;
  top: 25px !important;
  left: 10px;
  border: none !important;
  z-index: 3 !important;
}

.btn-complete {
  background-color: $ethera-green !important;
  border: none !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 18px !important;

  &:active,
  &:focus {
    background-color: $ethera-green !important;
  }
}
.ethera-check-button-color {
  color: #a8b482;
}
.modal-body {
  padding: 0 !important;
}

.modal-heading {
  background-color: $ethera-gray-light;
  display: inline-block;
  padding: 0.571rem 1.3rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select__control {
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  border: 1px solid $ethera-gray !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.input.form-control {
  background-color: transparent !important;
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}
.mb-0_5px {
  margin-bottom: 0.5rem;
}

.table > :not(:last-child) > :last-child {
  white-space: nowrap;
}

.select-w-70 {
  width: 70% !important;

  @include breakpoint-down(xSmall) {
    width: -webkit-fill-available !important;
  }
}

.select-w-35 {
  width: 35% !important;
}

.radius-15 {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.radius-25 {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.input-maxHeight {
  height: 5.6rem !important;
}
.mb-0-formGroup {
  margin-bottom: 0 !important;
}

.darkLayoutWhiteBackground {
  color: $black !important;
  border-color: $ethera-income-head !important;
  background: $ethera-income-head !important;
}

.time-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.Popover-custom-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.cbMvwV:hover {
  background-color: $ethera-gray-light;
}

.creditBalance-invoiceList {
  background-color: #d0d8e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
}

/////////////////////////////////////////////  Report Screen ///////////////////////////

.header-bgColor {
  background-color: $bg-ethera;
  @include breakpoint-down(xxxSmall) {
    padding: 0;
  }
}

.report-icons {
  margin-right: 0.3rem !important;
}
.report-card {
  background-color: $white !important;
  border: 1px solid #dedfdd;
  border-radius: 14px;
  margin: 0.5rem;

  @include breakpoint-up(xxxSLarge) {
    & span {
      font-size: 15px;
    }

    & p {
      font-size: 15px;
    }

    & h3 {
      font-size: 28px;
    }

    & svg {
      margin-right: 10px;
    }
  }

  .report-heading {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-left: 2rem;
  }
  .report-YTD-head {
    background-color: $ethera-YTD-head;
  }
  .report-income-head {
    background-color: $ethera-income-head;
  }
  .report-project-head {
    background-color: $ethera-project-head;
  }
  .report-icons-head {
    justify-content: space-evenly;

    &--icons {
      display: flex;
      align-items: center;
    }
  }
}
.recharts-text {
  font-size: 11px !important;
}
.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
  border-right: 1px solid #e2dbc8;
  height: calc(100% - 5.2rem);
}
.reports-box {
  background-color: #fafafa;
  height: 15rem;

  .report-title-div {
    background-color: $ethera-project-head;
    font-size: 16px;
    font-weight: bold;
    width: fit-content;
    padding: 0.7rem 1rem;
    margin-bottom: 0.5rem;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    color: $black;
    .billing-icon {
      margin-top: -4px;
      margin-right: 3px;
    }
  }
  .billing-btn {
    display: inline-grid;
    .billing-income-btn {
      background-color: $white !important;
      color: $black !important;
      border-radius: 10px;
      font-size: 13px !important;
      font-weight: bold;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.attendance-Name-field {
  display: flex;
  flex-direction: column;

  & span {
    font-size: 10px;
    text-transform: capitalize;
  }

  & p {
    margin: 0;
  }

  & svg {
    margin-right: 10px;
  }
}

.object-fit-cover {
  object-fit: cover !important;
}

.contained {
  object-fit: cover;
  overflow: hidden !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.popover-scroll {
  & div {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

.flat-picker-sm {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.12rem 1rem !important;
}

// Row Styling
.Row-styling {
  display: flex;
  flex-direction: row;
  padding: 3rem 2rem 1rem 2rem;
}

.resize-none {
  resize: none !important;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.flex-1 {
  flex: 1;
}

// textarea resize
.resize-0 {
  resize: none;
}

// text ellipsis
.text-ellipsis {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-button {
  display: flex;
  padding: 0 1.5rem;
  gap: 1rem;
}

.td-60 {
  max-width: 60%;
}

.gap-05 {
  gap: 0.5rem;
}

// calendar event
fc-event-title {
  color: white !important;
}

.cancel-and-save-end {
  @include flex-end-center;

  @include breakpoint-down(xxxSmall) {
    justify-content: center;
  }

  & button:first-child {
    padding: $btn-sm-padding-without-icon;
  }

  & button:last-child {
    padding: $btn-padding;
  }
}

.modal-paddings {
  padding: 0 1rem;
}

.dark-background {
  color: $black;
  border-color: $white;
  background: $white;
}

.bg-invert {
  background-color: #fff;
}

.f-bold {
  font-weight: bold;
}

.link {
  color: #3f71c4 !important;
}

.underline {
  text-decoration: underline !important;
}

.bg-gray {
  background-color: #d0d8e5;
}

.justify-between {
  justify-content: space-between;
}

.react-select-icon {
  background: #323a50;
  padding: 1px 4px;
  border-radius: 10px;
  & svg {
    color: white;
  }
}
.add-client-button-side {
  display: flex;
  flex-direction: row;
  width: '100%';
  justify-content: end;
}
// Display
.d-f-center {
  @include flex-start-center;
}

.xSmall-up-between {
  flex-wrap: wrap;
  @include breakpoint-up(xSmall) {
    justify-content: space-between !important;
  }
}
.xSmall-up-between-booking {
  @include breakpoint-up(xSmall) {
    justify-content: space-between !important;
  }
}
.xSmall-up-start {
  @include breakpoint-up(xSmall) {
    justify-content: flex-start !important;
    align-items: center;
  }
}
.client-headers {
  @include breakpoint-down(xSmall) {
    margin-bottom: 1rem;
  }
}
.custom-timeline {
  background-color: transparent;
  height: 35px;
  margin-left: 6px;
  border-left: 1px solid #dbdbdb;
  position: relative;

  &__icon {
    margin-left: 20px;
  }

  &__horizontal {
    width: 12px;
    height: 1px;
    background-color: #dbdbdb;

    position: absolute;
    bottom: 5px;
  }

  &:last-child {
    border-left: none;

    &::before {
      content: '';
      height: 30px;
      left: 0;
      top: 0px;
      position: absolute;
      border-left: 1px solid #dbdbdb;
    }
  }
}

.documentFiles {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.minHeightInput {
  min-height: 5.9rem;
}

.booking-badge {
  padding: 8px 10px !important;
}
.mr-19 {
  margin-top: -19px !important;
  margin-left: 0.6rem !important;
}
.modal-credit-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button:first-child {
    margin-right: 1rem;
  }
}

.connect-button {
  box-shadow: none !important;
  color: $black !important;
  span {
    padding: 0 !important;
  }
}
.open-modal {
  padding-right: 0px !important;
}
.main-box-pagination-bg-color {
  background: $bg-ethera !important;
}
.main-box-pagination {
  height: 2.7rem !important;
  position: relative !important;
}

@media only screen and (min-device-width: 200px) and (max-device-width: 350px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-box-pagination {
    height: 5.5rem !important;
    position: relative !important;
  }
}

.white-space {
  white-space: nowrap;
}
.black-color {
  color: #000 !important;
}

.black-color {
  color: #000 !important;
}
.pagination-position-fixed {
  position: fixed;
  bottom: -1px;
  width: -webkit-fill-available;
  background-color: #fff;
}
.add-service,
.add-service-main {
  @include breakpoint-down(xxxSmall) {
    margin-top: 1rem;
    width: 100%;
  }
}
.add-service-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.add-service::before {
  left: 10px !important;
  top: 6px !important;
}

.formIconSearch {
  margin-top: 1rem;
  & ul {
    background: #f9f9f9;
    padding: 2rem 1rem 1rem 1rem;
    margin-top: -20px;
    border-radius: 14px;
    max-height: 140px;
    overflow-y: scroll;

    & li {
      margin-left: 5px;
      list-style: none;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & p {
        margin: 0 !important;

        & strong {
          margin-left: 10px;
        }

        & svg {
          margin-left: 10px !important;
          cursor: pointer;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      & span {
        padding: 0 4px;
        margin-right: 10px;
        background-color: $ethera-green;
        color: $white;
        border-radius: 20px;
        font-size: 10px;
      }
    }
  }
}

.add-service::before {
  left: 9px !important;
  top: 4px !important;
}

.position-relative {
  position: relative !important;
}
.z-index-11 {
  z-index: 11;
}
.z-index-9999999 {
  z-index: 9999999;
}
.z-index-99 {
  z-index: 99 !important;
}
.position-sticky {
  position: sticky;
}
@media (min-width: 1200px) {
  .large-space-input {
    margin-left: 1rem;
  }
}
@media (max-width: 800px) {
  .btn-danger {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
  .btn-success {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .set-in-mobile-preview {
    margin-left: 7px !important;
  }
}
@media (max-width: 580px) {
  .set-in-mobile-preview {
    margin-left: 7px !important;
  }
  .clients__row--edit {
    white-space: nowrap;
  }
}
@media (max-width: 500px) {
  .set-msg-in-mobile-preview {
    margin-right: 0 !important;
  }
}

.form-check-input {
  margin-top: 0;
}
.form-checkbox-styling {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.active-booking-color {
  color: #4cbd3b !important;
  background-color: #c8f9c1 !important;
  border: 1px solid #4cbd3b !important;
  font-weight: 300 !important;
}
.inactive-padding {
  padding: 6px 10px !important;
}
.cancelled-padding {
  padding: 6px 9.5px !important;
}
.active-padding {
  padding: 6px 22px !important;
}
.update-button-padding {
  padding: 0.61rem 0.9rem;
}

//** Client Documents Styling
.completed-document {
  background-color: #d1d8e5;
  padding: 0.5rem 1.6rem;
  width: fit-content;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  margin: 1rem 0rem;
}
.fw-800 {
  font-weight: 800 !important;
}
.list-group-document {
  font-size: small;
  border-right: none;
  border-left: none;
}
.List-padding-left {
  padding-left: 3rem;
}
.document-URL {
  color: $black !important;
}
.document-URL:hover {
  color: $black !important;
}
.view-check-document {
  background-color: #a9b583;
  border-radius: 50%;
  padding: 0.15rem;
  margin-left: 0.4rem;
}
.black {
  color: $black;
}
.fc-daygrid-day {
  border: 1px solid #e2dbc8 !important;
}
.fc-timegrid-slot-minor {
  border: 1px solid #e2dbc8 !important;
}

.message-box-styling {
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 10px;
  margin-top: 4px;
}
.message-box-styling:focus-visible {
  outline: none !important;
}
.pt-2rem {
  padding-top: 2.2rem;
}
.delete-spinner-size {
  width: 12.5px;
  height: 12.5px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}
@media (max-width: 1000px) {
  .height-setting {
    height: 38vh !important;
    margin-bottom: 5rem !important;
  }
}
.paid-padding-btn {
  padding: 8px 27px !important;
}

.file-name {
  width: 20rem;
  min-height: 1rem;
  font-weight: 600;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 600px) {
  .file-name {
    width: 15rem;
  }
}
@media (max-width: 600px) {
  .file-name {
    width: 10rem;
  }
}
@media (min-width: 1000px) {
  .uploader-margin {
    margin: 1rem 0 1rem 0.4rem;
  }
}
.billing-edit-button {
  background-color: transparent !important;
  border: none !important;
  padding: 0;
}
.billing-edit-button:active {
  background-color: none !important;
}
.pdl-1 {
  padding-left: 14px !important;
}
.spinner-size {
  width: 12.7px;
  height: 12.7px;
}
.large-spinner-size {
  width: 32px;
  height: 32px;
}
.spinner-size-same {
  width: 12.38px;
  height: 12.4px;
}
.spinner-size-update {
  width: 12.43px;
  height: 12.7px;
}
.checkbox-create-password-label {
  margin-bottom: 0.1rem !important;
}
.checkbox-create-password {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: left;
}
.mb-0-mb {
  margin-bottom: 0 !important;
}
.add-card-spinner-size {
  width: 12.3px !important;
  height: 12.45px !important;
}
.check-size {
  width: 14px;
  height: 15px;
}

.pr-1rem {
  padding-right: 1rem;
}
.mr-1rem {
  margin-right: 1rem;
}
.mr-11px {
  margin-right: 11px;
}
.mr-01 {
  margin-right: 20px !important;
}
.pl-09px {
  padding-left: 09px !important;
}
.pl-08px {
  padding-left: 08px !important;
}
.Client-overView--tags-disabled-btn-color {
  background-color: rgb(233, 233, 233) !important;
}
.paid-btn-padding {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.privacy-policy-pd {
  padding: 3rem;
}
.privacy-policy-body {
  background-color: #fefbf4;
}
@include breakpoint-up(xSmall) {
  .sm-screen-setting {
    margin-bottom: 0 !important;
  }
}
@include breakpoint-down(xSmall) {
  .privacy-policy-pd {
    padding: 1rem;
  }
  .ul-points {
    padding-left: 0.5rem;
  }
  .privacy-policy-ol-list {
    padding-left: 1rem;
  }
  .privacy-policy-body {
    padding: 0;
  }
  .sm-screen-setting {
    width: 97%;
    margin-left: 10px;
    margin-right: 20px;
  }
  .mlr-1rem {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .mr-1rem {
    margin-right: 0;
  }
  .pr-1rem {
    padding-right: 0;
  }
  .d-sm-none {
    display: none !important;
  }

  .client_profile--submission {
    width: 100%;
    .sm-screen-setting {
      width: 100%;
      margin: 0 08px !important;
    }
    .sm-screen-setting:nth-last-child(1) {
      width: 100%;
      margin: 0 0px !important;
    }
  }

  .modal-dialog-centered {
    min-height: calc(77% - 1rem);
    margin: 6rem auto !important;
  }
  .margin-top-sm-screen {
    top: 5.5rem !important;
  }
  .add_client_header {
    flex-direction: column-reverse;
    padding: 0 2rem !important;
    .sm-screen-ml-2 {
      margin-left: 1.6rem !important;
    }
    .sm-screen-ml-2-input {
      margin-left: 2rem !important;
    }
    .edit-status-button {
      margin-left: auto;
      margin-bottom: 0.7rem;
      button {
        margin-left: 0 !important;
      }
    }
  }
}
.word-break {
  word-break: break-word;
}
@include breakpoint-down(xSmall) {
  .attendance-status {
    width: 100%;
    margin-top: 1rem;
  }
}
@include breakpoint-up(med) {
  .show-mini-navbar {
    display: none !important;
  }
}
@include breakpoint-up(large11) {
  .AppointmentSelectors_left-dates {
    margin-left: 7px;
  }
}
@include breakpoint-down(med) {
  .show-mini-navbar-hide-small-screen {
    display: none !important;
  }

  .Client-overView--status {
    margin-top: 1rem;
  }
  .padding-bottom-layout {
    padding: calc(4.5rem + 1.1rem + 1.3rem) 0 0 !important;
  }
}
@include breakpoint-down(medLg) {
  .sm-screen-ml-0 {
    margin-left: 0 !important;
  }
  .sm-screen-styling {
    display: block !important;
  }
}
@include breakpoint-down(large) {
  .appointment-sections {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm-screen-mb {
    margin-bottom: 1rem !important;
  }
}
@include breakpoint-down(large11) {
  .sm-screen-white-space {
    white-space: nowrap;
  }
}
.sm-screen-ml-0 {
  margin-left: 1rem;
}
@include breakpoint-down(small) {
  .sm-s-w-100 {
    width: 100%;
  }
}
.template-table--body__bars {
  font-weight: bold;
  color: #000;
}
@include breakpoint-down(xSmall) {
  .template-table--Form {
    margin: 10px auto !important;
    padding: 10px !important;
  }
}
@include breakpoint-down(xxSmall) {
  .delete-modal-body {
    padding: 1.4rem 2rem !important;
  }
  .padding-bottom-layout {
    padding: calc(6rem + 1.1rem + 1.3rem) 0 0 !important;
  }
  .template-table--Form {
    margin: 5px !important;
    padding: 5px !important;
  }
  .sm-screen-mb-1 {
    margin-bottom: 1rem !important;
  }
}

@include breakpoint-down(xxxSmall) {
  .form-select {
    padding: 0.371rem 0.25rem 0.371rem 1rem;
  }
  .col-pr-1rem {
    padding-right: 0;
  }
  .heading-para {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  .template-table--Form__actions__checkbox {
    margin-left: 0;
  }
  .template-card .head .head-row .head-row-span {
    padding: 0 1rem;
  }
  .sm-screen-padding-add-billing {
    padding: 0.5rem 0 1rem 0;
  }
  .template-table--Form {
    margin: 5px !important;
    padding: 5px !important;
  }
  .Client-overView--status {
    flex-wrap: wrap;
    width: 100% !important;
    .Client-overView--status__button {
      width: 100%;
      margin: 0;
      button {
        width: 100%;
      }
    }
  }
}
.mt-4px {
  margin-top: 4px;
}
.mt-2px {
  margin-top: 2px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-10px {
  margin-top: 10px;
}

.start_calendar_day {
  margin-right: 10px;
  @include breakpoint-down(xSmall) {
    margin-right: 0px;
  }
}
button[disabled] {
  cursor: not-allowed !important;
  pointer-events: visible !important;
  opacity: 0.5 !important;
}
.hover-effect-none:disabled {
  color: rgba(40, 48, 70, 0.6);
}
.minWidth-50 {
  min-width: 50% !important;
}
.cursor-pointer:hover {
  cursor: pointer;
}
.card-body-bar-chart {
  @include breakpoint-down(xxSmall) {
    padding-left: 0;
    padding-right: 10px;
  }
}
@media (max-width: 350px) {
  .template-table--Form {
    margin: 5px !important;
    padding: 5px !important;
  }
  .custom-table-notes-forms tbody tr td .question {
    white-space: nowrap;
  }
  .heading-para {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .sm-screen-d-block {
    display: block;
  }
  .sm-screen-fit {
    padding: 1rem 0.5rem !important;
  }
  .sm-screen-overlay li {
    padding: 0.5rem !important;
  }
}
.sm-pe-0 {
  padding-right: 0;
}
.marginX-large-screen {
  margin-right: 0.8rem !important;
}
.width-100-sm-screen {
  min-width: 170px;
}
.hover-effect-none:hover,
.hover-effect-none:active,
.hover-effect-none:focus {
  box-shadow: none !important;
  color: rgba(40, 48, 70, 0.6) !important;
}
@media (max-width: 765px) {
  .width-100-sm-screen {
    width: 100% !important;
    margin-top: 0.5rem;
  }
  .marginX-large-screen {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .sm-screen-mt-0 {
    margin-top: 0 !important;
    width: 100%;
    text-align: center;
  }
  .sm-pe-0 {
    padding-right: 1rem;
  }
}
.emailHover {
  font-size: 15px;
}
.emailHover:hover {
  color: #336;
  text-decoration: underline;
  transition: 0.3s ease;
}
.ul-points {
  list-style: none;
  font-family: 'sofia-pro', Sans-serif;
}

/* Use ::before to create a pseudo-element for the bullet */
.ul-points li::before {
  content: '';
  display: inline-block;
  width: 6px; /* Adjust the width of the bullet */
  height: 6px; /* Adjust the height of the bullet */
  background-color: black; /* Fill the bullet with the desired color */
  border-radius: 50%; /* Make the bullet circular (for a filled circle) */
  margin-right: 10px; /* Optional: Add some space between the bullet and the text */
}
.privacy-policy-footer ul li {
  background-color: #6c8861;
  color: $white;
  padding: 10px 10px !important;
  border-radius: 5px !important;
}
.privacy-policy-footer ul .facebook-pd {
  padding: 10px 14px !important;
}
.horizantel-row-privacy {
  background-color: lightgray !important;
}
.privacy-policy-footer-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  @include breakpoint-down(xSmall) {
    display: block;
    justify-content: center;
    text-align: center;
  }
}
@include breakpoint-down(xSmall) {
  .privacy-policy-footer ul {
    justify-content: center;
    padding-left: 0 !important;
  }
}

.tos {
  font-family: 'sofia-pro', Sans-serif !important;
  font-size: 60px;
  line-height: 4rem;
  color: #2e2e39;
  @include breakpoint-down(large) {
    font-size: 45px;
    line-height: 3rem;
  }
  @include breakpoint-down(med) {
    font-size: 40px;
  }
  @include breakpoint-down(small) {
    font-size: 35px;
  }
  @include breakpoint-down(xSmall) {
    font-size: 30px;
  }
  @include breakpoint-down(xxSmall) {
    font-size: 20px;
    line-height: 2rem;
  }
}
.fc-scroller div div table,
.fc-scroller div table {
  border-bottom-style: initial;
}
#react-select-14-listbox {
  min-width: 180px;
}
#react-select-15-listbox {
  min-width: 180px;
}
#react-select-16-listbox {
  min-width: 180px;
}
#react-select-17-listbox {
  min-width: 180px;
}
.add-client-document {
  padding-left: 40px;
  padding-right: 40px;
}

.booking-export-section {
  @include breakpoint-down(large) {
    display: flex;
    justify-content: flex-end;
  }
  .text-decoration-none {
    @include breakpoint-down(large) {
      width: 50%;
    }
    @include breakpoint-down(small) {
      width: 100%;
    }
  }
}
.small-screen-full-width button {
  @include breakpoint-down(xSmall) {
    width: 100%;
  }
}
.card {
  background-color: #fff;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}
.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(34, 41, 47, 0.2);
  border-radius: 0.357rem;
}
.table,
.list-group,
.popover {
  --bs-body-bg: transparent;
}

.dropdown-menu,
.popover {
  --bs-body-bg: #fff;
}

.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: #555;
  color: #555;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -12px;
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: #555;
  color: #555;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 12px;
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: #555;
  color: #555;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
.ml-15px {
  margin-left: 15px;
}
@keyframes dot-flashing {
  0% {
    background-color: #555;
  }
  50%,
  100% {
    background-color: #999;
  }
}
.services-modal {
  max-width: 800px;
  border-radius: 16px !important;
  .ethera-modal-top-background {
    background-color: white !important;
    padding: 2rem 2rem;
  }
}
.react-time-input-picker-wrapper {
  border-bottom: none !important;
  height: 32px !important;
}
.react-time-input-picker {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 24px !important;
  font-size: 14px !important;
}
.react-time-input-picker input {
  margin: 0 0 !important;
}
.react-time-input-picker div:first-child input:focus {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
}
.react-time-input-picker div:nth-child(3) input:focus {
  border-top-right-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}
.time-selectors > div {
  border: none !important;
  padding: 0 !important;
}
.text-clip {
  text-overflow: clip !important;
}
.notification-badge {
  padding: 3px 7px !important;
}

.client-flex-wrap {
  @include breakpoint-down(xxSmall) {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  button {
    @include breakpoint-down(xxSmall) {
      width: 100%;
      margin-left: 5px;
    }
  }
}
input:disabled {
  background-color: #efefef !important;
  cursor: not-allowed !important;
}
.check-avail-btn {
  font-size: 12px !important;
  padding: 10px 3px !important;
}
.mb-0px {
  margin-bottom: 0 !important;
}
.w-full-100 {
  width: 100% !important;
}
.w-fit-content {
  width: fit-content !important;
}
.fill-rooms {
  fill: transparent;
}
.disabled-rooms {
  fill: rgba(0, 0, 0, 0.4);
}
.available-rooms {
  fill: transparent;
}
.fill-rooms:hover {
  fill: rgba(0, 0, 0, 0.4);
}
.active {
  fill: #a8b48296 !important;
}
.checkbox_item .title {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e9ec;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 25px;
  text-align: center;
}

.checkbox_item .checkbox_wrap {
  position: relative;
  display: block;
  cursor: pointer;
  width: 40px;
  height: 20px;
  margin: 0 auto 50px;
}

.checkbox_item:last-child .checkbox_wrap {
  margin-bottom: 0;
}

.checkbox_item .checkbox_wrap .checkbox_inp {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.checkbox_item .checkbox_wrap .checkbox_mark {
  display: inline-block;
  position: relative;
  border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after {
  content: '';
  position: absolute;
  transition: all 0.5s ease;
}

/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark {
  background: transparent;
  width: 40px;
  height: 20px;
  padding: 2px;
  border: 1px solid #6e6b7b;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before {
  top: 4px;
  left: 3px;
  width: 10px;
  height: 10px;
  background: #6b9879;
  border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark {
  background: transparent;
}

.checkbox_item.citem_1
  .checkbox_wrap
  .checkbox_inp:checked
  ~ .checkbox_mark:before {
  left: 25px;
}

.toggle-checkbox {
  margin-right: 15px;
  padding-left: 30px;
  padding: 10px 15px 10px 30px;
}
.toggle-checkbox span {
  margin-left: 14px;
}
@media screen and (max-width: 600px) {
  .room-list-checkbox {
    min-width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .room-map-checkbox {
    min-width: 50%;
  }
}
.chat-header-container {
  padding: 0 20px;
}
.chat-header {
  height: 80px;
  border-bottom: 1px solid #e2dbc8;
}
.chat-sub-header {
  height: 100%;
}
.chat-small-heading {
  padding: 30px 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}
.search-container {
  margin-bottom: 15px;
}
.chat-list {
  list-style: none;
  padding-left: 0;
}
.chat-sidebar-container {
  background-color: white;
  padding: 0 20px;
  height: calc(100vh - 142px);
  border-right: 1px solid #e2dbc8;
  overflow-y: auto;
}
.message-time {
  font-size: 10px;
}
.chat-mini-paragraph {
  margin-top: 5px;
  font-size: 12px;
}
.chat-li {
  padding: 15px 0;
  border-bottom: 1px solid #e2dbc8;
}
.row-container {
  display: grid;
  grid-template-columns: 48px 1fr 1fr 1fr;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  min-height: 48px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.cell-container {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0px;
  max-width: 100%;
  min-width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding: 0.72rem 1.5rem;
  word-break: break-word;
  border-right: 1px solid #f1f0ed;
}
.cell-container span {
  color: #6e6b7b;
}
.empty-cell {
  white-space-collapse: collapse;
  text-wrap: nowrap;
  font-weight: 400;
}
.chat-main-container {
  background-color: white;
  height: calc(100vh - 142px);
  border-left: 1px solid #f1f0ed;
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.chatbox {
  overflow: auto;
}
.input-container {
  border: 1px solid #815e0c;
  padding: 10px 20px;
  background-color: #fefbf4;
  border-radius: 8px;
  position: relative;
}
.input-sub-container {
  column-gap: 10px;
}
.input-container--left-container {
  flex: 0 1 30px;
  height: 100%;
}
.input-container--middle-container {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
}
.input-container--middle-container:empty:before {
  content: attr(data-text);
  color: #999999;
}
.input-container--middle-container:focus-visible {
  outline: none;
}
.input-container--right-container {
  display: flex;
  align-items: center;
  flex: 0 1 30px;
  height: 100%;
}
.sender {
  margin-bottom: 10px;
}

.receiver {
  margin-bottom: 10px;
}
.empty-chat-box {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.chat-file-preview {
  border-radius: 8px;
  position: relative;
}
.chat-file-preview-image {
  border-radius: 8px;
}
.pdf-previewer {
  background: rgb(251, 240, 213);
  border-radius: 8px;
  padding: 10px;
  width: 150px;

  align-items: center;
}
.pdf-previewer--icon {
  margin-right: 5px;
}
.chat-file-preview-loader {
  position: absolute;
  left: 0;
}
.custom-option {
  border-bottom: 1px solid #e2dbc8;
  padding: 10px;
  // border-bottom: 1px solid black;
}
.custom-option:hover {
  background-color: #fefbf4;
}
.custom-option--div {
  margin-top: 5px;
  text-transform: capitalize;
}
.cancel-styling {
  background: rgba(234, 84, 85, 0.1) !important;
}
.cancel-styling td {
  color: #ea5455 !important;
}
.error-feedback {
  margin-bottom: 10px;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #ea5455;
}
.appointment-row-container {
  display: flex;
  border-bottom: 1px solid #f1f0ed !important;
}
.appointment-cell-container-w-15 {
  min-width: 15rem;
}
.appointment-cell-container-w-10 {
  min-width: 10rem;
}
.appointment-cell-container-w-12 {
  min-width: 12rem;
}
.appointment-dotted-btn {
  border-style: dashed;
  border-width: 1px;
  border-color: #783e34;
  border-radius: 8px;
  padding: 6px 26px;
  background-color: unset;
  color: #783e34;
}
.sidebar-modal-title {
  color: black;
  font-weight: 700;
}
.react-dataTable-headers .rdt_TableHead .rdt_TableHeadRow {
  height: 50px;
}
.react-dataTable-headers
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol
  .rdt_TableCol_Sortable {
  color: black !important;
  font-size: 16px;
  font-weight: bold;
}
.booking-export-btn {
  min-width: 100px;
}
.appointment-services-color {
  color: #6e6b7b;
  font-size: 13px;
}
.appointment-services-color p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.telehealth-template {
  width: 75%;
  @include breakpoint-down(med) {
    width: auto;
    padding-right: 2rem;
  }
}
.notes-text-editor {
  width: 100%;
}
.notes-text-editor > * {
  width: 100%;
}
.esign-container {
  width: 100%;
}
.diagnosis-treatment-container {
  max-width: full;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 20px;
}
.diagnosis-list {
  list-style: none;
  margin-top: 5px;
  padding-left: 0;
}
.diagnosis-list li {
  margin-bottom: 10px;
}
.added-diagnosis-list li {
  margin-bottom: 10px;
}
.diagonsis-title-container {
  column-gap: 5px;
}
.flat-picker-height {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.diagnosis-modal {
  width: 550px !important;
  max-width: 550px;
}
.react-select__menu-list {
  background-color: red;
}
.margin-of-text {
  margin-left: 5rem;
}
.guard-text {
  font-size: 10px;
  margin-left: 5px;
}
.Disable-button {
  background-color: #f0f0f0;
  color: #666;
  border: none;
  padding: 5px 20px;
  border-radius: 20px;
}
.Continue-button {
  background-color: green;
  color: #ffff;
  border: none;
  padding: 8px 20px;
  border-radius: 20px;
  width: 120px;
}
.auth-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.otp-input {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  margin-left: 20px;
}
.otp-input-2 {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  border: 1px solid;
  margin-bottom: 6rem;
}
.auth-card {
  height: 621px;
  width: 522px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7rem;
  border-radius: 4rem;
}
.diagnosis-tab::-webkit-scrollbar {
  display: none;
}
.tab-scroll {
  max-height: 600px;
  max-width: auto;
  overflow-y: auto;
}
.check-password-2fa {
  width: 110px;
  height: 35px;
}
.toastEmail {
  overflow-x: 'scroll';
  flex: 1;
  margin-right: '5px';
  width: '10px';
}
.animate-it {
  display: inline-block;
  animation: move 4s ease-in-out infinite;
  position: relative;
  padding-inline: 1rem;

  @keyframes move {
    0% {
      transform: translate(0, 0);
      left: 0%;
    }
    75%,
    100% {
      transform: translate(-100%, 0);
      left: 100%;
    }
  }
}
.tooltip-button {
  background: transparent;
  background-color: none;
  border: none;
  padding: 0;
  margin: 0;
}
.tooltip-button {
  position: relative;
  display: inline-block;
}

// .tooltip-button::after {
//   content: attr(title);
// position: absolute;
// top: -30px;
// left: 50%;
// transform: translateX(-50%);
// padding: 5px;
// background-color: #fefbf4;
// color: black;
// border: 1px solid #815e0c;
// border-radius: 8px;
// font-size: 14px;
// white-space: nowrap;
// visibility: hidden;
// opacity: 0;
// transition: visibility 0s, opacity 0.3s;
// }
.tooltip-content {
  visibility: 'hidden' !important;
  cursor: default;
  opacity: 0;
}
.icon-tooltip:hover + .tooltip-content {
  visibility: visible;
  opacity: 1;
}
.insurance-table {
  border-collapse: 'collapse';
  table-layout: 'fixed';
  border-spacing: '0px';
  padding: '20px';
  width: '100%';
  height: '100%';
  box-sizing: 'border-box';
  text-indent: 'initial';
  border: '1px solid black';
}
.Table-row {
  display: flex;
  align-items: start;
  gap: 12px;
}
.equal-cols {
  white-space: normal;
  width: 100% !important;
}
.form-section-label {
  margin-bottom: 0;
  min-width: 8px;
  font-weight: 600;
}
.claim-form-radio-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.boolean {
  display: inline-flex;
  vertical-align: top;
  cursor: pointer;
  padding: 0;
  user-select: text;
}
.boolean Input {
  margin-right: 8px;
  margin-left: 5px;
  // height: 32px;
}
.input-style {
  width: fit-content !important;
  font-size: 14px;
}
.modifier-Input {
  text-align: center;
  width: 28px;
}
.table-padding td {
  padding: 5px;
}
.table-padding tr td {
  vertical-align: top;
}
.inline-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.table-padding Input {
  max-height: 32px;
  max-width: 100%;
}

.table-padding Select {
  max-height: 32px;
}
.electronic-signature {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.Table-row {
  display: flex;
  align-items: start;
  text-wrap: nowrap;
  gap: 12px;
}
.equal-cols {
  white-space: normal;
  width: 100% !important;
}
.Table-wrapper {
  display: flex;
  flex-direction: column;
  // gap: 8px;
  // padding: 0 8px;
}
.middle-initial {
  width: 36px;
}
.nature-illnes td {
  border-style: none;
}
.unbilled-appointments {
  
}
.unbilled-appointments .cell-container{
 
  align-items: flex-start;
}
