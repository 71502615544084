.custom-table-notes-forms {
  width: 100%;
  tr th {
    font-weight: 400;
    color: #585f6c;
  }
  tr:hover {
    cursor: pointer;
  }
  thead tr th,
  tbody tr td {
    padding: 1rem !important;
  }
  tbody {
    .type-of-note {
      @include breakpoint-up(med) {
        min-width: 300px !important;
      }
    }
  }
}

#notes-Modal {
  @include breakpoint-up(med) {
    max-width: 700px !important;
  }

  @include breakpoint-up(large11) {
    max-width: 900px !important;
  }
}
