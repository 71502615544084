.image-left-direction {
  display: flex;
  margin: 0.3rem 0;
  .chat-box-div {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
.image-right-direction {
  align-self: flex-end;
  margin-top: 1rem;
  .chat-box-div {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: none !important;
}
.rdw-editor-main {
  div {
    height: auto;
  }
  background-color: $white !important;
  border: none;
  padding: 0rem 0.8rem;
  max-height: 120px !important;
  min-height: 40px !important;
  overflow-y: auto !important;
}
.rdw-editor-wrapper {
  border: 0.4px solid #999;
  display: flex;
  flex-direction: column-reverse;
}
.receiver-message-box {
  max-width: 19rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 13px;
  width: fit-content;
  margin-right: auto;
  min-width: 160px;

  &--div {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f6dd97;
    padding: 0.6rem 0.6rem;
    p {
      @include breakpoint-down(xxSmall) {
        max-width: 13rem;
      }
      width: fit-content;
      max-width: 18rem;
      min-width: 160px;
      word-wrap: break-word;
    }
    .file_name_chat_box {
      @include breakpoint-down(xxSmall) {
        max-width: 14rem !important;
      }
    }
    a {
      @include breakpoint-down(xxSmall) {
        max-width: 14rem !important;
      }
      width: fit-content;
      max-width: 18rem !important;
      min-width: 160px !important;
      word-wrap: break-word;
    }
    ol,
    ul {
      @include breakpoint-down(xxSmall) {
        max-width: 14rem;
      }
      padding-left: 1rem !important;
      margin-bottom: 0;
      width: fit-content;
      max-width: 19rem !important;
      min-width: 160px !important;
    }
  }

  &--time {
    width: 100%;
    display: flex;
    & span {
      margin-left: auto;
      font-size: 8px;
      margin-top: 1px;
      margin-right: 10px;
    }
  }

  & p {
    margin: 0px;
  }

  @include breakpoint-down(xxSmall) {
    max-width: 14rem;
  }
  @include breakpoint-up(large) {
    max-width: 40rem;
  }
}
.sender-message-box {
  max-width: 19rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 13px;
  width: fit-content;
  margin-left: auto;
  min-width: 160px;
  @include breakpoint-down(xxxSmall) { 
    max-width: 15rem !important;
  }
  @include breakpoint-down(small300) { 
    max-width: 13rem !important;
  }
  &--div {
    @include breakpoint-down(xxSmall) {
      p {
        max-width: 10rem;
      }
    }
    padding: 0.6rem 0.6rem;
    background-color: #d1d8e5 !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    p {
      width: fit-content;
      max-width: 18rem;
      min-width: 160px;
      word-wrap: break-word;
      @include breakpoint-down(xxxSmall) { 
        max-width: 14rem !important;
      }
      @include breakpoint-down(small300) { 
        max-width: 12rem !important;
      }
    }
    a {
      word-wrap: break-word;
      width: fit-content;
      max-width: 18rem !important;
      min-width: 160px !important;
    }
    ol,
    ul {
      padding-left: 1rem !important;
      margin-bottom: 0;
      width: fit-content;
      max-width: 19rem !important;
      min-width: 160px !important;
    }
  }

  &--time {
    width: 100%;
    display: flex;
    & span {
      margin-left: auto;
      font-size: 8px;
      margin-top: 1px;
      margin-right: 10px;
    }
  }

  & p {
    margin: 0px;
  }

  @include breakpoint-up(large) {
    max-width: 40rem;
  }
}
.receiver-message-box:first-child {
  border-top-right-radius: 12px;
}
.receiver-message-box:last-child {
  border-bottom-right-radius: 12px;
}

.error-msg {
  color: red !important;
}
.chat-message::-webkit-scrollbar {
  width: 8px;
}
.chat-message::-webkit-scrollbar-track {
  box-shadow: inset 0 0 25px #dfdfdf;
  border-radius: 12px;
}
.chat-message::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #6281ae;
  border-radius: 12px;
}

.text-ellipsis {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hover-black:hover {
  color: black !important;
}

.avatar-image-chatbox {
  width: 40px !important;
  height: 40px !important;
  @include breakpoint-down(xxSmall) {
    width: 30px !important;
    height: 30px !important;
}
}