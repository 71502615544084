.add-payment {
    &-title {
        &-numbering {
            background-color: #f8f8f8;
            padding: 5px;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            text-align: center;
        }
    }
    &-card {
        margin: 0 0.8rem;
        width: 100%;
        &-box {
            &-title {
                margin-top: 3px;
                font-size: 20px;
                color: black;
                margin-bottom: 10px;
            }
            &-detail {
                margin-top: 10px;
                background-color: #f8f8f8;
                width: 100%;
                &-table {
                    thead {
                        tr {
                            th {
                                background-color: transparent !important;
                                border: none;
                            }
                        }
                    }
                    tbody {
                        tr {
                            th,
                            td {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.border-radius-5 {
    border-radius: 4px !important;
    background-color: #13be71 !important;
    border: none !important;
}
.add-payment-total div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}
.add-payment-checkbox {
    div {
        margin-bottom: 0 !important;
    }
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}
.p-01 {
    padding: 4px 1rem;
}

.payment-card-detail {
    background-color: $white;
    padding: 13px;
    
}
.payment-card-detail p {
    padding: 0 5px;
    font-size: 12px;
}
.link-color{
    color: blue !important;
}