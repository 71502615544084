.superbill-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.superbill-flex-column {
  flex-direction: column;
  align-items: flex-start;
}
.superbill-flex-end-self {
  align-self: flex-end !important;
}
.superbill-font-size-11px {
  font-size: 11px !important;
}
.superbill-noWrap {
  white-space: break-spaces !important;
}
.superbill-body {
  padding: 1rem 1rem 0 3rem;
}
.superbill-padding-left-3rem {
  padding-left: 3rem;
}
.superbill-data-table {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        border-right: none !important;
        border-left: none !important;

        padding: 0.72rem 0.5rem !important;
        .rdt_TableCol_Sortable {
          display: block !important;
        }
      }
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        border-right: none !important;
        border-left: none !important;
        display: block !important;
        padding: 0.72rem 0.9rem !important;
      }
    }
  }
}
.client_superbill--submission {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  margin: 2rem 0 0 0;
}

.superbill-overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  background-color: #fefbf4 !important;
  background-color: #fefbf4 !important;
  overflow-x: hidden;
  transition: 0.5s;
}
.superbill-open {
  width: 100%;
  height: 100% !important;
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.superbill-overlay-content {
  background-color: #fff !important;
  position: relative;
  width: 100%; /* 100% width */
  margin-top: 30px;
}

.superbill-overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  display: block;
  transition: 0.3s;
  z-index: inherit;
}

.superbill-overlay .closebtn {
  position: absolute;
  top: 20px;
  left: 10px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .superbill-overlay a {
    font-size: 20px;
  }
  .superbill-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
