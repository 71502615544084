.bgThead {
  & th {
    background: #faf6ed !important;
  }
}

.bgThead-blue {
  & th {
    background: $ethera-project-head !important;
  }
}

.bgThead-white {
  & th {
    background-color: $white !important;
    border-bottom: 1px solid $black !important;
  }
}

.tableRow {
  cursor: pointer;
  white-space: nowrap;
}

.maxWidth-cardBalance {
  max-width: 500px;
  background-color: #fafafa !important;
}

.bg-yellow{
  background: #fefbf4 !important;
}

.borderLess-cell {
  border-bottom: none;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  & td {
    border-width: 0px !important;
  }
}

.bgGray {
  background-color: #d0d8e5;
  color: $gray-700;
  font-weight: bold;
}

.bgTransparent {
  background-color: transparent;

  & td {
    text-align: right;
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 3px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
    &.selected {
      border-bottom: 2px solid #000;
      font-weight: 600;
    }
    .arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }
      &.left {
        transform: rotate(-135deg) translate(-50%);
      }
      &.right {
        transform: rotate(45deg);
      }
    }
    &.disabled {
      pointer-events: none;
      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
