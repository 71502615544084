.bookings-header {
  @include flex-center-start;
  @include flex-column;

  @include breakpoint-up(small) {
  }

  &--left {
    @include flex-center-start;
    @include flex-column;
    margin-bottom: 1rem;
    width: 100%;

    @include breakpoint-up(small) {
      @include flex-row;
      width: 98%;
      & button {
        margin-left: 1rem;
      }
    }
    @include breakpoint-down(small) { 
      & .reset-btn-div {
        width: 100%;
        margin-top: 1rem;
        & button {
          width: 100%;
        }
      }
    }

    & button {
      width: 100%;
    }

    &__calendar {
      width: 100%;
      margin-bottom: 1rem;

      @include breakpoint-up(small) {
        padding-right: 0.7rem;
      }
    }
  }

  &--selects {
    @include flex-stretch-center;
    @include flex-column;
    width: -webkit-fill-available;
    gap: 0.5rem;
    @include breakpoint-up(small) {
      @include flex-row;
      justify-content: flex-start;
    }

    @include breakpoint-up (large) {
        margin-bottom: 1rem;
    }

    @include breakpoint-up(large11) {
      width: fit-content;
      margin-bottom: 0;

    }
  }

  @include breakpoint-up(large11) {
    @include flex-row;
    align-items: center;

    &--left {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        &__calendar {
            margin-bottom: 0;
            width: fit-content;
        }

        & button {
            width: fit-content;
        }
    }
  }
}
