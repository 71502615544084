.btn-large-transparent {
  padding: 0.5rem 1.5rem !important;

  background-color: transparent !important;
  border: none !important;
  text-align: left;
  &:active {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
  & strong {
    color: $ethera-golden;
  }
}
.button-primary {
  background-color: #f99b40 !important;
  padding: 8px 15px 8px 15px !important;
  border: none !important;
  position: relative;
  color: $white !important;

  &:active {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:focus {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}
.add-more {
  background-color: $ethera-button-secondary !important;
  border: 1px solid rgba($ethera-text-color, 0.5) !important;
  color: $gray-800 !important;
  padding: 0.63rem 1.8rem !important;
  &:active {
    background-color: $ethera-button-secondary !important;
  }
  &:focus {
    background-color: $ethera-button-secondary !important;
  }
  & span {
    white-space: nowrap;
  }
}

.add-more-gray {
  background-color: #CFD8E5 !important;
  border: 1px solid rgba(#CFD8E5, 0.5) !important;
  color: #32394B !important;
  padding: 0.5rem 1.5rem !important;
  &:active {
    background-color: #CFD8E5!important;
  }
  &:focus {
    background-color: #CFD8E5 !important;
  }
  & span {
    white-space: nowrap;
  }
}

// form group buttons
.form {
  &__group {
    margin-top: 15px !important;
    margin-left: -10px !important;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__input {
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    background-color: rgba(#fefefe, 0.5);
    border: none;
    font-family: 'Poppins', sans-serif;
    color: inherit;
    border-bottom: 3px solid transparent;
    width: 90%;
    display: block;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: #999;
    }

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(#000, 0.15);
      border-bottom: 3px solid #783e34;
    }

    &:focus:invalid {
      border-bottom: 3px solid #45556f;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
    -webkit-transform: translateY(-4rem);
    -moz-transform: translateY(-4rem);
    -ms-transform: translateY(-4rem);
    -o-transform: translateY(-4rem);
  }

  &__radio-group {
    width: 49%;
    display: inline-block;
  }

  &__radio-input {
    visibility: hidden;
  }

  &__radio-label {
    font-size: 16px;
    cursor: pointer;
    position: relative;
    padding-left: 3.5rem;
  }

  &__radio-button {
    height: 2rem;
    width: 2rem;
    border: 3px solid #783e34;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.4rem;

    &::after {
      content: '';
      display: block;
      height: 1rem;
      width: 1rem;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #783e34;

      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;

      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);

      transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
    }
  }

  &__radio-input:checked + &__radio-label &__radio-button {
    &::after {
      opacity: 1;
    }
  }
}

.button-success {
  @include flex-center;
  background-color: $button-green !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $button-green !important;
  }
  &:focus {
    background-color: $button-green !important;
  }
}

.button-white {
  @include flex-center;
  background-color: $white !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  color: $black !important;
  font-weight: 400;
  position: relative;
  font-size: 15px;

  &:active {
    background-color: $white !important;
  }
  &:focus {
    background-color: $white !important;
  }
}
.button-black {
  @include flex-center;
  background-color: #283046 !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  color: $white !important;
  font-weight: 400;
  position: relative;
  font-size: 15px;

  &:active {
    background-color: #283046 !important;
  }
  &:focus {
    background-color: #283046 !important;
  }
}
.button-signature {
  @include flex-center;
  background-color: $ethera-blue !important;
  color: $white !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $ethera-blue !important;
  }
  &:focus {
    background-color: $ethera-blue !important;
  }
}

.button-danger {
  margin-left: 10px;
  @include flex-center;
  background-color: $danger !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $danger !important;
  }
  &:focus {
    background-color: $danger !important;
  }

  @include breakpoint-down(med) {
    margin-bottom: 1rem;
  }
}

.button-cancel {
  @include flex-center;
  background-color: $ethera-project-head !important;
  border: none !important;
  color: rgba($bg-dark, 0.6);
  position: relative;

  &:active {
    background-color: $ethera-project-head !important;
  }
  &:focus {
    background-color: $ethera-project-head !important;
  }
}

.btn-transparent {
  width: -webkit-fill-available;
  background: transparent !important;
  border: none;
  color: black !important;
  text-align: center;
  box-shadow: none !important;

  &:active {
    background-color: transparent !important;
    color: black !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: transparent !important;
    color: black !important;
    box-shadow: none !important;
  }
}

.button-reset {
  background-color: $ethera-gray-light !important;
  border: 1px solid rgba($ethera-gray-text, 0.5) !important;
  color: $ethera-gray-text !important;
  white-space: nowrap !important;
  &:active {
    background-color: $ethera-gray-light !important;
  }
  &:focus {
    background-color: $ethera-gray-light !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
  & span {
    white-space: nowrap;
  }
}
.button-back {
  background-color: $ethera-gray-light;
  border: 1px solid #757575;
  color: $ethera-gray-text;
  width: 130px;
  height: 40px;
}
.button-continue {
 background-color: $button-green !important;
  border: none !important;
  color: white;
  width: 150px;
  height: 40px;
}
.reset-otp-button {
  background-color: $button-green !important;
  border: none ;
  color: white;
  width: 180px;
  height: 40px;
}
